import React from "react";
import "./Clients.css";
import ClientsTable from "./clients-table/ClientTable";

const Users = () => {
  return (
    <div className="users-wrapper">
      <div className="users-card">
        <ClientsTable />
      </div>
    </div>
  );
};

export default Users;
