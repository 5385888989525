import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./main-component/home/Home";
import Users from "./main-component/users/Users";
import Jobs from "./main-component/jobs/Jobs";
import Payments from "./main-component/payments/Payments";
import Clients from "./main-component/clients/Clients";

export default function MainSection() {
  return (
    <div className="main-section">
      <Switch>
        <Route exact path="/dashboard" component={Home} />
        <Route path="/dashboard/artisans" component={Users} />
        <Route path="/dashboard/jobs" component={Jobs} />
        <Route path="/dashboard/payments" component={Payments} />
        <Route path="/dashboard/clients" component={Clients} />
      </Switch>
    </div>
  );
}
