import React, { useState, useEffect, useCallback } from "react";
import { Table } from "antd";
import axios from "axios";
import styled from "styled-components";
import { callApiWithToken } from "../../../../../../_helpers/callApi";
import { useAuthContext } from "../../../../../auth/auth-context/AuthContext";
import { useHistory } from "react-router";



const div = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #002e5a;
`;

export default function JobsTable({ activeState }) {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  const context = useAuthContext();
  const { setCurrentLoan } = context;

  const rightJobs = useCallback(() => {
    if (activeState === 1) {
      return "https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_loans?category=personal_loan&status=under%20review&page=1";
    } else if (activeState === 2) {
      return "https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_loans_by_payment?category=personal_loan&loan_closed=false&page=1";
    }else if (activeState === 3) {
      return "https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_loans_by_payment?category=personal_loan&loan_closed=true&page=1";
    }else if (activeState === 4) {
      return "https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_loans?category=business_loan&status=under%20review&page=1";
    }else if (activeState === 5) {
      return "https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_loans_by_payment?category=business_loan&loan_closed=false&page=1";
    }else if (activeState === 6) {
      return "https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_loans_by_payment?category=business_loan&loan_closed=false&page=1";
    }


  }, [activeState]);

  const statusFormat = () => {
    if (activeState === 1) {
      return "text-success";
    } else if (activeState === 2) {
      return "text-danger";
    } else {
      return "text-warning";
    }
  };

  const formatWork = category => {
    if (category) {
      return (
        category.charAt(0).toUpperCase() +
        category.slice(1).replace(/_/g, " ").toUpperCase()
      );
    } else {
      return category;
    }
  };
  useEffect(() => {
    const fetchJobs = async id => {
      setLoading(true);
      try {
        const response = await axios(rightJobs(), {
          headers: {
            Token: `Bearer ${id}`,
          },
        });

        if (response.status === 200) {
          setJobs(response.data.data.loans);
        }
      } catch {
      } finally {
        setLoading(false);
      }
    };
    callApiWithToken(fetchJobs);
  }, [rightJobs]);

  const amountParser = (_amount) => {
    let amount = _amount.substring(0, (_amount.length - 2));
    return "₦" + amount
  }

  const columns = [
    {
      key: "firstname",
      title: "Name",
      dataIndex: "",
      width: 150,
      render: record => (
        <div>{`${record.firstname} ${record.lastname} `}</div>
      ),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      render: text => <div>{text}</div>,
    },
    {
      key: "phone_no",
      title: "Phone No",
      dataIndex: "phone_no",
      render: text => <div>{text}</div>,
    },
    {
      key: "application_date",
      title: "Application Date",
      dataIndex: "application_date",
      render: text => <div>{text}</div>,
    },
    {
      key: "loan_amount_requested",
      title: "Amount",
      dataIndex: "loan_amount_requested",
      render: text => <div>{amountParser(text)}</div>,
    },
    {
      key: "loan_duration",
      title: "Duration",
      width: 100,
      dataIndex: "loan_duration",
      render: text => <div>{text}</div>,
    },
    {
      key: "action",
      title: "",
      width: 100,
      dataIndex: "",
      render: (record, isVerified) => (
        
          <button
            className="manage"
            onClick={() => {
              setCurrentLoan(record);
              history.push("/loan-details")
            }}
          >
            View Loan
          </button>
      ),
    },
    
  ];

  return (
    <Table
      columns={columns}
      dataSource={jobs}
      loading={loading}
      pagination={true}
      scroll={{ y: "51vh", x: "max-content" }}
    />
  );
}
