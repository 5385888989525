import React, { useState, useEffect } from "react";
import { Table, Dropdown, Menu } from "antd";
import axios from "axios";
import styled from "styled-components";
import "../Users.css";
import { useAuthContext } from "../../../../../auth/auth-context/AuthContext";
import { Toast } from "../../../../../styled-components/Toast";
import { useHistory } from "react-router";
import { callApiWithToken } from "../../../../../../_helpers/callApi";

const div = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #002e5a;
`;

export default function UserLoansTable() {
  const [loading, setLoading] = useState(false);

  const context = useAuthContext();
  const { currentArtisan } = context;
  console.log("user", currentArtisan);

  const loanTypeParser = (type) => {
      if (type === "business_loan"){
          return "Business Loan"
      }else if (type === "personal_loan"){
        return "Personal Loan"
      }
  }

  const columns = [
    
    {
      key: "application_date",
      title: "Application Date",
      dataIndex: "",
      render: record => (
        <div>{`${record.application_date}`}</div>
      ),
    },
    {
      key: "loan_type",
      title: "Loan Type",
      dataIndex: "",
      render: record => (
        <div>{`${ loanTypeParser(record.loan_type)}`}</div>
      ),
    },
    {
      key: "BVN",
      title: "BVN",
      dataIndex: "BVN",
      render: text => <div>{text}</div>,
    },
    {
        key: "house_address",
        title: "Address",
        dataIndex: "house_address",
        render: text => <div>{text}</div>,
    },
    {
      key: "loan_application_number",
      title: "Application No",
      dataIndex: "loan_application_number",
      render: text => <div>{text}</div>,
    },
    {
        key: "loan_duration",
        title: "Duration",
        width: 200,
        dataIndex: "loan_duration",
        render: text => <div>{text}</div>,
    },
    {
        key: "loan_purpose",
        title: "Loan Purpose",
        dataIndex: "loan_purpose",
        render: text => <div>{text}</div>,
    },
    {
        key: "approved",
        title: "Approval Status",
        dataIndex: "approved",
        render: text => <div>{text}</div>,
    },    


    
  ];

  return (
    <Table
      columns={columns}
      dataSource={currentArtisan.loans}
      loading={loading}
      pagination={true}
      scroll={{ y: "51vh", x: "max-content" }}
    />
  );
}
