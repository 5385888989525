import React, { useState, useEffect } from "react";
import "./UserProfile.scss";
import { useAuthContext } from "../../../../../auth/auth-context/AuthContext";
import styled from "styled-components";
import { Toast } from "../../../../../styled-components/Toast";
import { callApiWithToken } from "../../../../../../_helpers/callApi";
import axios from "axios";
import UserLoansTable from "./UserLoansTable";
import userImg from '../../../../../../img/profile.png'

const Input = styled.input`
  padding: 1em;
  color: green;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 50px;
  background: #d2d7db;
  border-radius: 4px;
  border: 1px solid #d2d7db;
  margin-top: 2px;
  text-transform: capitalize;
  outline: none;

  &:hover {
    border: none;
  }
`;

const TextArea = styled.textarea`
  padding: 1em;
  color: green;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  background: #d2d7db;
  border-radius: 4px;
  border: 1px solid #d2d7db;
  margin-top: 2px;
  text-transform: capitalize;
  outline: none;

  &:hover {
    border: none;
  }
`;
export default function UserProfile() {
  const [loading, setLoading] = useState(false);


  const context = useAuthContext();
  const { artisanId, userIdToken , currentArtisan} = context;

  
  return (
    <div className="profile-wrapper">
          <div className="profile-nav">
            <h1>User Profile</h1>
          </div>
          <div className="profile">
            <div className="artisan-avatar">
              <div className="artisan-img">
                <img src={userImg} alt="" />
              </div>
              <div className="flex-div">
                <p>
                  {`${currentArtisan.firstname} ${currentArtisan.lastname}`}
                </p>
              </div>
            </div>

            <div className="artisan-details">
              <div className="input-container">
                <label htmlFor="">User Name</label>
                <Input
                  value={`${currentArtisan.title} ${currentArtisan.firstname} ${currentArtisan.lastname}`}
                  type="text"
                  disabled={true}
                />
              </div>
              <div className="input-container">
                <label htmlFor="">Email Address</label>
                <Input
                  value={currentArtisan.email}
                  type="text"
                  disabled={true}
                />
              </div>
              <div className="input-container">
                <label htmlFor="">Phone No</label>
                <Input
                  value={currentArtisan.phone_no}
                  type="text"
                  disabled={true}
                />
              </div>
              <div className="input-container">
                <label htmlFor="">Gender</label>
                <Input value={currentArtisan.gender} type="text" disabled={true} />
              </div>
            </div>
          </div>
          <UserLoansTable/>
        </div>
  )
}
