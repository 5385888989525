import React from "react";
import "./Payments.css";
import PaymentsTable from "./payments-table/PaymentsTable";

const Payments = () => {
 
  return (
    <div className="payments-wrapper">
      <div className="payments-card">
        <PaymentsTable />
      </div>
    </div>
  );
};

export default Payments;
