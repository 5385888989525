import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../../../firebase/firebase.js";
import axios from "axios";

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  const [currentArtisan, setCurrentArtisan] = useState({});
  const [currentClient, setCurrentClient] = useState({});
  const [totalUsers, settotalUsers] = useState(null);
  const [totalPersonalLoans, settotalPersonalLoans] = useState(null);
  const [totalBusinessLoans, settotalBusinessLoans] = useState(null);
  const [clientId, setClientId] = useState("");
  const [artisanId, setArtisanId] = useState("");
  const [userIdToken, setUserIdToken] = useState(null);
  const [currentLoan, setCurrentLoan] = useState(null);

  const fetchUsers = async id => {
    try {
      console.log("this is message", id);
      const response = await axios(
        `https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_client?page_number=1`,
        {
          headers: {
            token: `Bearer ${id}`,
          },
        }
      );
      if (response.status === 200) {
        settotalUsers(response.data.data.total_users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPersonalLoans = async id => {
    try {
      const response = await axios(
        ` https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_loans?category=personal_loan&status=under%20review&page=1`,
        {
          headers: {
            Token: `Bearer ${id}`,
          },
        }
      );
      console.log("res", response);
      if (response.status === 200) {
        settotalPersonalLoans(response.data.data.total_loans);
      }
    } catch {}
  };

  const fetchBusinessLoans = async id => {
    try {
      const response = await axios(
        ` https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_loans?category=business_loan&status=under%20review&page=1`,
        {
          headers: {
            Token: `Bearer ${id}`,
          },
        }
      );
      console.log("res", response);
      if (response.status === 200) {
        settotalBusinessLoans(response.data.data.total_loans);
      }
    } catch {}
  };

  const getArtisan = async id => {
    try {
      const response = await axios(
        `https://helpclean-artisan-jgn56hmtbq-uc.a.run.app/v1/helpclean_artisan_api/get_user_by_id/${artisanId}`,
        {
          headers: {
            Token: `Bearer ${id}`,
          },
        }
      );
      console.log("res", response);
      if (response.status === 200) {
        setCurrentArtisan(response.data.Artisan);
      }
    } catch {
    } finally {
    }
  };

  const signin = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const signout = () => {
    return auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user !== null) {
        user.getIdToken().then(idToken => {
          setUserIdToken(idToken);
          fetchPersonalLoans(idToken);
          fetchUsers(idToken);
          fetchBusinessLoans(idToken)
        });
      }
    });
    return unsubscribe;
  }, [currentUser, userIdToken]);

  const value = {
    currentUser,
    userIdToken,
    signin,
    signout,
    currentArtisan,
    setCurrentArtisan,
    setCurrentClient,
    currentClient,
    totalUsers,
    totalPersonalLoans,
    totalBusinessLoans,
    clientId,
    setClientId,
    artisanId,
    setArtisanId,
    getArtisan,
    currentLoan,
    setCurrentLoan
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
