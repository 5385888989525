import React, { useState, useEffect } from "react";
import { Table, Menu } from "antd";
import axios from "axios";

import "../Users.css";
import { useAuthContext } from "../../../../../auth/auth-context/AuthContext";
import { Toast } from "../../../../../styled-components/Toast";
import { useHistory } from "react-router";
import { callApiWithToken } from "../../../../../../_helpers/callApi";

export default function UserTable() {
	const history = useHistory();
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedArtisan, setSelectedArtisan] = useState(false);
	const [refreshTable, setRefreshTable] = useState(false);

	const context = useAuthContext();
	const { setCurrentArtisan, setArtisanId } = context;

	const verifyArtisan = async id => {
		setLoading(true);
		try {
			await axios.post(
				"https://helpclean-artisan-jgn56hmtbq-uc.a.run.app/v1/helpclean_artisan_api/verify_artisan",
				{
					verified: true,
					artisan_id: selectedArtisan,
				},
				{
					headers: {
						Token: `Bearer ${id}`,
					},
				}
			);
			Toast.fire({
				icon: "success",
				title: "Artisan Verified",
			});
			setRefreshTable(!refreshTable);
		} catch {
		} finally {
			setLoading(false);
		}
	};

	const unVerifyArtisan = async id => {
		setLoading(true);
		try {
			await axios.post(
				"https://helpclean-artisan-jgn56hmtbq-uc.a.run.app/v1/helpclean_artisan_api/verify_artisan",
				{
					verified: false,
					artisan_id: selectedArtisan,
				},
				{
					headers: {
						Token: `Bearer ${id}`,
					},
				}
			);
			Toast.fire({
				icon: "success",
				title: "Artisan Unverified",
			});
			setRefreshTable(!refreshTable);
		} catch {
		} finally {
			setLoading(false);
		}
	};

	const fetchUsers = async id => {
		setLoading(true);
		try {
			const response = await axios(
				"https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/get_all_client?page_number=1",
				{
					headers: {
						Token: `Bearer ${id}`,
					},
				}
			);
			console.log("res", response);
			if (response.status === 200) {
				setUsers(response.data.data.users);
			}
		} catch {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		callApiWithToken(fetchUsers);
	}, [refreshTable]);
	const columns = [
		{
			key: "first_name",
			title: "User Name",
			dataIndex: "",
			render: record => <div>{`${record.firstname} ${record.lastname} `}</div>,
		},
		{
			key: "phone_no",
			title: "Phone Number",
			dataIndex: "phone_no",
			render: text => <div>{text}</div>,
		},
		{
			key: "email",
			title: "Email Address",
			width: 300,
			dataIndex: "email",
			render: text => <div>{text}</div>,
		},
		{
			key: "gender",
			title: "Gender",
			width: 300,
			dataIndex: "gender",
			render: text => <div>{text}</div>,
		},
		{
			key: "action",
			title: "",
			width: 100,
			dataIndex: "",
			render: (record, isVerified) => (
				<button
					className="manage"
					onClick={() => {
						setCurrentArtisan(record);
						setArtisanId(record.userID);
						history.push("/user-profile");
					}}
				>
					View User
				</button>
			),
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={users}
			loading={loading}
			pagination={true}
			scroll={{ y: "51vh", x: "max-content" }}
		/>
	);
}
