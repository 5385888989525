import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyDXgJYOLeQ19MrM5S81j1ilju0-AZGt57c",
	authDomain: "greengo-loans.firebaseapp.com",
	databaseURL: "https://greengo-loans-default-rtdb.firebaseio.com",
	projectId: "greengo-loans",
	storageBucket: "greengo-loans.appspot.com",
	messagingSenderId: "915610678579",
	appId: "1:915610678579:web:5b9377c6a4fa2487b33a46",
	measurementId: "G-Z36RT6VMF4",
};
// Initialize Firebase
if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();

export { auth, firebase as default };
