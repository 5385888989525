import React, { useState, useEffect } from "react";
import "./ClientProfile.scss";
import { useAuthContext } from "../../../../../auth/auth-context/AuthContext";
import styled from "styled-components";
import axios from "axios";
import { callApiWithToken } from "../../../../../../_helpers/callApi";

const Input = styled.input`
  padding: 1em;
  color: green;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 50px;
  background: #d2d7db;
  border-radius: 4px;
  border: 1px solid #d2d7db;
  margin-top: 2px;
  text-transform: capitalize;
  outline: none;

  &:hover {
    border: none;
  }
`;

export default function UserProfile() {
  const context = useAuthContext();
  const [currentClient, setCurrentClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const { clientId } = context;

  useEffect(() => {
    const getClient = async id => {
      setLoading(true);
      try {
        const response = await axios(
          `https://helpclean-client-jgn56hmtbq-uc.a.run.app/v1/helpclean_api/get_user_by_id/${clientId}`,
          {
            headers: {
              Token: `Bearer ${id}`,
            },
          }
        );
       
        if (response.status === 200) {
          setCurrentClient(response.data.client);
        }
      } catch {
      } finally {
        setLoading(false);
      }
    };
    callApiWithToken(getClient);
  }, [clientId]);

  const returnClient = () => {
    if (currentClient === null) {
      return (
        <div className="loading-div">
          <span className="spinner-border mx-3" role="status"></span>
        </div>
      );
    } else {
      return (
        <div className="profile-wrapper">
          <div className="profile-nav">
            <h1>Client's Profile</h1>
          </div>
          <div className="profile">
            <div className="flex-column">
              <div className="artisan-avatar">
                <div className="artisan-img">
                  <img src={currentClient.profile_picture} alt="" />
                </div>
                <div className="flex-div">
                  <p>
                    {`${currentClient.first_name} ${currentClient.last_name}`}
                  </p>
                </div>

                <span>{currentClient.phone_number}</span>
              </div>
            </div>

            <div className="artisan-details">
              <div className="input-container">
                <label htmlFor="">Client's Name</label>
                <Input
                  value={`${currentClient.first_name} ${currentClient.last_name}`}
                  type="text"
                  disabled={true}
                />
              </div>
              <div className="input-container">
                <label htmlFor="">Email Address</label>
                <Input
                  value={currentClient.email}
                  type="text"
                  disabled={true}
                />
              </div>
              <div className="input-container">
                <label htmlFor="">Device</label>
                <Input
                  value={currentClient.device}
                  type="text"
                  disabled={true}
                />
              </div>
              <div className="input-container">
                <label htmlFor="">Language</label>
                <Input
                  value={currentClient.language}
                  type="text"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return loading ? (
    <div className="loading-div">
      <span className="spinner-border mx-3" role="status"></span>
    </div>
  ) : (
    returnClient()
  );
}
