import React, { useState, useEffect } from "react";
import { Table, Dropdown, Menu } from "antd";
import axios from "axios";
import styled from "styled-components";
import "../Clients.css";
import { useAuthContext } from "../../../../../auth/auth-context/AuthContext";
import { useHistory } from "react-router";
import { callApiWithToken } from "../../../../../../_helpers/callApi";

const div = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #002e5a;
`;

export default function UserTable() {
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const context = useAuthContext();
  const { setCurrentClient, setClientId } = context;

  const actions = (
    <Menu className="dropdown">
      <Menu.Item
        key="1"
        className="dropdown-menu"
        onClick={() => history.push("/client-profile")}
      >
        <p>View Profile</p>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const fetchClients = async id => {
      setLoading(true);
      try {
        const response = await axios(
          ` https://helpclean-client-jgn56hmtbq-uc.a.run.app/v1/helpclean_api/get_all_client/1`,
          {
            headers: {
              Token: `Bearer ${id}`,
            },
          }
        );
        console.log("res", response);
        if (response.status === 200) {
          setClients(response.data.users);
        }
      } catch {
      } finally {
        setLoading(false);
      }
    };

    callApiWithToken(fetchClients);
  }, []);

  const columns = [
    {
      key: "first_name",
      title: "Artisan Name",
      dataIndex: "",
      render: record => (
        <div>{`${record.first_name} ${record.last_name} `}</div>
      ),
    },
    {
      key: "phone_number",
      title: "Phone Number",
      dataIndex: "phone_number",
      render: text => <div>{text}</div>,
    },
    {
      key: "email",
      title: "Email Address",
      width: 300,
      dataIndex: "email",
      render: text => <div>{text}</div>,
    },

    {
      key: "action",
      title: "",
      width: 100,
      dataIndex: "",
      render: (record, isVerified) => (
        <Dropdown
          overlay={actions}
          placement={"bottomRight"}
          trigger={["click"]}
        >
          <button
            className="manage"
            onClick={() => {
              setCurrentClient(record);
              setClientId(record.userID);
            }}
          >
            Manage
          </button>
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={clients}
      loading={loading}
      pagination={true}
      scroll={{ y: "51vh", x: "max-content" }}
    />
  );
}
