import React, { useState, useEffect } from "react";
import "./Loan.scss";
import { useAuthContext } from "../../../../../auth/auth-context/AuthContext";
import styled from "styled-components";
import { Toast } from "../../../../../styled-components/Toast";
import { callApiWithToken } from "../../../../../../_helpers/callApi";
import axios from "axios";
import UserLoansTable from "./UserLoansTable";
import userImg from '../../../../../../img/profile.png'

export const Button = styled.button`
  color: white;
  letter-spacing: 1px;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: 'poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0px;
  cursor: pointer;
  height: 50px;
  background-color: #00A550;
  padding: 0 24px;
  width: 100%;
  border-radius: 6px;
  white-space: nowrap;
  margin-top: 30px;
  text-decoration: none;
  transition: all .4s ease;

  &:hover {
    color: white;
    background-color: var(--dark);
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: #FFF
  }

`
const Input = styled.input`
  padding: 1em;
  color: green;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 50px;
  background: #d2d7db;
  border-radius: 4px;
  border: 1px solid #d2d7db;
  margin-top: 2px;
  text-transform: capitalize;
  outline: none;

  &:hover {
    border: none;
  }
`;

const TextArea = styled.textarea`
  padding: 1em;
  color: green;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  background: #d2d7db;
  border-radius: 4px;
  border: 1px solid #d2d7db;
  margin-top: 2px;
  text-transform: capitalize;
  outline: none;

  &:hover {
    border: none;
  }
`;
export default function UserProfile() {
  const [loading, setLoading] = useState(false);


  const context = useAuthContext();
  const { currentLoan, userIdToken } = context;

  const amountParser = (_amount) => {
    if (_amount == undefined){
      return ""
    }else{
      let amount = _amount.substring(0, (_amount.length - 2));
      return "₦" + amount
    }
    
  }

  const loanTypeParser = (type) => {
    if (type === "business_loan") {
      return "Business Loan"
    } else if (type === "personal_loan") {
      return "Personal Loan"
    }
  }

  const fnDisplayLoan = () => {
    if (currentLoan.loan_type === "business_loan") {
      return true
    } else {
      return false
    }
  }

    // const verifyArtisan = async () => {
  //   setLoading(true);
  //   try {
  //     await axios.post(
  //       `https://helpclean-artisan-jgn56hmtbq-uc.a.run.app/v1/helpclean_artisan_api/verify_artisan`,
  //       {
  //         verified: true,
  //         artisan_id: currentArtisan.userID,
  //       },
  //       {
  //         headers: {
  //           Token: `Bearer ${userIdToken}`,
  //         },
  //       }
  //     );
  //     Toast.fire({
  //       icon: "success",
  //       title: "Artisan Verified",
  //     });
  //   } catch {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const approve = async () => {
    setLoading(true);
    try {
      await axios.post(
        `https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/approve_loan`,
        {
          loan_application_number: currentLoan.loan_application_number,
          approval_status: true,
          loan_duration: currentLoan.loan_duration,
          amount_approved: currentLoan.amount_approved,
          interest_rate: currentLoan.interest_rate,
        },
        {
          headers: {
            Token: `Bearer ${userIdToken}`,
          },
        }
      );
      Toast.fire({
        icon: "success",
        title: "Loan Approved",
      });
    } catch {
    } finally {
      setLoading(false);
    }

  };

  const reject = async () =>{
    setLoading(true);
    try {
      await axios.post(
        `https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/approve_loan`,
        {
          loan_application_number: currentLoan.loan_application_number,
          approval_status: false,
          loan_duration: currentLoan.loan_duration,
          amount_approved: currentLoan.amount_approved,
          interest_rate: currentLoan.interest_rate,
        },
        {
          headers: {
            Token: `Bearer ${userIdToken}`,
          },
        }
      );
      Toast.fire({
        icon: "success",
        title: "Loan Approved",
      });
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const recordLoanPayment = async () =>{
    setLoading(true);
    try {
      await axios.post(
        `https://loan-image-h55dgx5wta-uc.a.run.app/v1/loans/log_loan_payment`,
        {
          loan_application_number: currentLoan.loan_application_number,
          amount_paid: currentLoan.amount_paid,
          close_loan: currentLoan.close_loan
        },
        {
          headers: {
            Token: `Bearer ${userIdToken}`,
          },
        }
      );
      Toast.fire({
        icon: "success",
        title: "Loan Payment Recorded",
      });
    } catch {
    } finally {
      setLoading(false);
    }
  };



  return (
    fnDisplayLoan() ? (<div className="profile-wrapper">
      <div className="profile-nav">
        <h1>Loan Details</h1>
      </div>
      <div className="profile">
        <div className="artisan-avatar">
          <div className="artisan-img">
            <img src={userImg} alt="" />
          </div>
          <div className="flex-div">
            <p>
              {`${currentLoan.firstname} ${currentLoan.lastname}`}
            </p>
          </div>
          <p>
            {`${currentLoan.loan_application_number}`}
          </p>

          <div className="artisan-approval">
            <Button onClick={approve}>
              Approve Loan
              {loading && (
                <span className="spinner-border mx-3" role="status"></span>
              )}
            </Button>
            <Button onClick={reject}>
              Reject Loan
              {loading && (
                <span className="spinner-border mx-3" role="status"></span>
              )}
            </Button>
            <Button onClick={recordLoanPayment}>
              Record Loan Payment
              {loading && (
                <span className="spinner-border mx-3" role="status"></span>
              )}
            </Button>
          </div>

        </div>


        <div className="artisan-details">
          <div className="input-container">
            <label htmlFor="">User Name</label>
            <Input
              value={`${currentLoan.title} ${currentLoan.firstname} ${currentLoan.lastname}`}
              type="text"
              disabled={true}
            />
          </div>
          <div className="input-container">
            <label htmlFor="">Email Address</label>
            <Input
              value={currentLoan.email}
              type="text"
              disabled={true}
            />
          </div>
          <div className="input-container">
            <label htmlFor="">Phone No</label>
            <Input
              value={currentLoan.phone_no}
              type="text"
              disabled={true}
            />
          </div>
          <div className="input-container">
            <label htmlFor="">Gender</label>
            <Input value={currentLoan.gender} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Date of Birth</label>
            <Input value={currentLoan.date_of_birth} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Marital Status</label>
            <Input value={currentLoan.marital_status} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Housing Situation</label>
            <Input value={currentLoan.housing_situation} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Work Address</label>
            <Input value={currentLoan.work_address} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Application Date</label>
            <Input value={currentLoan.application_date} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Amount Applied For</label>
            <Input value={amountParser(currentLoan.loan_amount_requested)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Loan Duration</label>
            <Input value={`${currentLoan.loan_duration} Months`} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Loan Purpose</label>
            <Input value={currentLoan.loan_purpose} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Loan Type</label>
            <Input value={loanTypeParser(currentLoan.loan_type)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Amount Approved</label>
            <Input value={amountParser(currentLoan.amount_approved)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Amount Paid</label>
            <Input value={amountParser(currentLoan.amount_paid)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Approval Date</label>
            <Input value={currentLoan.approval_date} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Approved Loan Duration</label>
            <Input value={currentLoan.approved_loan_duration} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Monthly Income</label>
            <Input value={amountParser(currentLoan.monthly_income)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Next of Kin</label>
            <Input value={currentLoan.next_of_kin_address} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Next of Kin Name</label>
            <Input value={currentLoan.next_of_kin_name} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Next of Kin Phoneno</label>
            <Input value={currentLoan.next_of_kin_number} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Gurantor Name</label>
            <Input value={currentLoan.gurantor_name} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Gurantor Relationship</label>
            <Input value={currentLoan.gurantor_relationship} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Gurantor Work Address</label>
            <Input value={currentLoan.gurantor_work_address} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">How Did You Hear About Us</label>
            <Input value={currentLoan.how_did_you_hear_about_us} type="text" disabled={true} />
          </div>
          <Button>
            <a href={currentLoan.valid_id_card} target="_blank">View ID Card</a>
          </Button>

          <Button>
            <a href={currentLoan.account_statement} target="_blank">View Statement of Account</a>
          </Button>

          <Button>
            <a href={currentLoan.utility_bill} target="_blank">View Utility Bill</a>
          </Button>

          <Button>
            <a href={currentLoan.proof_of_business} target="_blank">View Proof of Business</a>
          </Button>

          <Button>
            <a href={currentLoan.cac_registration} target="_blank">View CAC Registration</a>
          </Button>

          <Button>
            <a href={currentLoan.gurantor_id_card} target="_blank">View Gurantor ID Card</a>
          </Button>

        </div>
      </div>
    </div>) : (<div className="profile-wrapper">
      <div className="profile-nav">
        <h1>Loan Details</h1>
      </div>
      <div className="profile">
        <div className="artisan-avatar">
          <div className="artisan-img">
            <img src={userImg} alt="" />
          </div>
          <div className="flex-div">
            <p>
              {`${currentLoan.firstname} ${currentLoan.lastname}`}
            </p>
          </div>
          <p>
            {`${currentLoan.loan_application_number}`}
          </p>

          <div className="artisan-approval">
            <Button onClick={approve}>
              Approve Loan
              {loading && (
                <span className="spinner-border mx-3" role="status"></span>
              )}
            </Button>
            <Button onClick={reject}>
              Reject Loan
              {loading && (
                <span className="spinner-border mx-3" role="status"></span>
              )}
            </Button>
            <Button onClick={recordLoanPayment}>
              Record Loan Payment
              {loading && (
                <span className="spinner-border mx-3" role="status"></span>
              )}
            </Button>
          </div>

        </div>


        <div className="artisan-details">
          <div className="input-container">
            <label htmlFor="">User Name</label>
            <Input
              value={`${currentLoan.title} ${currentLoan.firstname} ${currentLoan.lastname}`}
              type="text"
              disabled={true}
            />
          </div>
          <div className="input-container">
            <label htmlFor="">Email Address</label>
            <Input
              value={currentLoan.email}
              type="text"
              disabled={true}
            />
          </div>
          <div className="input-container">
            <label htmlFor="">Phone No</label>
            <Input
              value={currentLoan.phone_no}
              type="text"
              disabled={true}
            />
          </div>
          <div className="input-container">
            <label htmlFor="">Gender</label>
            <Input value={currentLoan.gender} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Date of Birth</label>
            <Input value={currentLoan.date_of_birth} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Marital Status</label>
            <Input value={currentLoan.marital_status} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Housing Situation</label>
            <Input value={currentLoan.housing_situation} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Work Address</label>
            <Input value={currentLoan.work_address} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Application Date</label>
            <Input value={currentLoan.application_date} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Amount Applied For</label>
            <Input value={amountParser(currentLoan.loan_amount_requested)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Amount Approved</label>
            <Input value={amountParser(currentLoan.amount_approved)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Amount Paid</label>
            <Input value={amountParser(currentLoan.amount_paid)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Approval Date</label>
            <Input value={currentLoan.approval_date} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Approved Loan Duration</label>
            <Input value={currentLoan.approved_loan_duration} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Loan Duration</label>
            <Input value={`${currentLoan.loan_duration} Months`} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Loan Purpose</label>
            <Input value={currentLoan.loan_purpose} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Loan Type</label>
            <Input value={loanTypeParser(currentLoan.loan_type)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Monthly Income</label>
            <Input value={amountParser(currentLoan.monthly_income)} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Next of Kin</label>
            <Input value={currentLoan.next_of_kin_address} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Next of Kin Name</label>
            <Input value={currentLoan.next_of_kin_name} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Next of Kin Phoneno</label>
            <Input value={currentLoan.next_of_kin_number} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Gurantor Name</label>
            <Input value={currentLoan.gurantor_name} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Gurantor Relationship</label>
            <Input value={currentLoan.gurantor_relationship} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">Gurantor Work Address</label>
            <Input value={currentLoan.gurantor_work_address} type="text" disabled={true} />
          </div>
          <div className="input-container">
            <label htmlFor="">How Did You Hear About Us</label>
            <Input value={currentLoan.how_did_you_hear_about_us} type="text" disabled={true} />
          </div>
          <Button>
            <a href={currentLoan.valid_id_card} target="_blank">View ID Card</a>
          </Button>

          <Button>
            <a href={currentLoan.account_statement} target="_blank">View Statement of Account</a>
          </Button>

          <Button>
            <a href={currentLoan.utility_bill} target="_blank">View Utility Bill</a>
          </Button>

          <Button>
            <a href={currentLoan.gurantor_id_card} target="_blank">View Gurantor ID Card</a>
          </Button>
        </div>
      </div>
    </div>)
  )
}
