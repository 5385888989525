import React from "react";
import Layout from "./Layout";
import './Dashboard.css'

export default function DashBoard() {
  return (
    <>
      <Layout />
    </>
  );
}
