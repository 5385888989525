import React, { useState } from "react";
import "./Jobs.css";
import JobsTable from "./jobs-table/JobsTable";

const Jobs = () => {

  const [activeState, toggleActiveState] = useState(1)

  const navs = [
    { id: 1, name: 'P-Pending' },
    { id: 2, name: 'P-Running' },
    { id: 3, name: 'P-Closed' },
    { id: 4, name: 'B-Pending' },
    { id: 5, name: 'B-Running' },
    { id: 6, name: 'B-Closed' }
   
  ]

  const toggleActive = (index) => {
    toggleActiveState(navs[index].id)
  }

  const toggleActiveStyles = (index) => {
    if (navs[index].id === activeState) {
      return 'active-tab'
    } else {
      return
    }
  }
  return (
    <div className="jobs-wrapper">
      <div className="jobs-card">
      <ul className="table-nav">
      {navs.map((nav, index) => (
        <li
          key={nav.id}
          className={`nav-items ${toggleActiveStyles(index)}`}
          onClick={() => toggleActive(index)}
        >
          {nav.name}
        </li>
      ))}
    </ul>
    <div className='mt-2'>
        <JobsTable activeState={activeState}/>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
