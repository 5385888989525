import React from "react";
import "./Users.css";
import UserTable from "./user-table/UserTable";

const Users = () => {
  return (
    <div className="users-wrapper">
      <div className="users-card">
        <UserTable />
      </div>
    </div>
  );
};

export default Users;
