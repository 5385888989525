import React from "react";
import { Route, useHistory } from "react-router-dom";
import { useAuthContext } from "./auth-context/AuthContext";


export  function ProtectedRoute({ component: Component, ...rest }) {
const history = useHistory()
  const context = useAuthContext()
  const { currentUser } = context
  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          return <Component {...props} />;
        } else {
          history.push('/')
        }
       
      }}
    />
  );
}
