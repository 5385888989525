import React from "react";
import { useLocation } from "react-router-dom";

export default function MainHeader() {
  let location = useLocation();
  const displayHeader = () => {
    if (location.pathname.includes("artisans")) {
      return "Manage Users";
    } else if (location.pathname.includes("api-keys")) {
      return "Api Keys";
    } else if (location.pathname.includes("payments")) {
      return "Payments";
    } else if (location.pathname.includes("jobs")) {
      return "All Loans";
    } else if (location.pathname.includes("top-up")) {
      return "Top up";
    } else if (location.pathname.includes("clients")) {
      return "Clients";
    } else {
      return "Home";
    }
  };
  return (
    <div className="main-header">
      <div className="heading">{displayHeader()}</div>
      <div className="header-icons">
        <span>
          <i className="las la-bell"></i>
        </span>
        <span>
          <i className="las la-user-circle"></i>
        </span>
      </div>
    </div>
  );
}
