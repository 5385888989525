import React, { useState, useEffect } from "react";
import { Table, Dropdown, Menu } from "antd";
import { useHistory } from "react-router";
import axios from "axios";
import styled from "styled-components";
import { Toast } from "../../../../../styled-components/Toast";
import { useAuthContext } from "../../../../../auth/auth-context/AuthContext";
import { callApiWithToken } from "../../../../../../_helpers/callApi";

const div = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #002e5a;
`;

export default function PaymentsTable() {
  const history = useHistory();
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bookingId, setBookingId] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const context = useAuthContext();
  const { setClientId, setArtisanId } = context;

  const fetchPayments = async id => {
    setLoading(true);
    try {
      const response = await axios(
        "https://helpclean-artisan-jgn56hmtbq-uc.a.run.app/v1/helpclean_artisan_api/all_payments",
        {
          headers: {
            Token: `Bearer ${id}`,
          },
        }
      );
      console.log("res", response);
      if (response.status === 200) {
        setPayments(response.data.data);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const confirmClientPayment = async id => {
    setLoading(true);
    try {
      const res = await axios.post(
        "https://helpclean-artisan-jgn56hmtbq-uc.a.run.app/v1/helpclean_artisan_api/update_payment_status",
        {
          status: "processing",
          booking_id: bookingId,
        },
        {
          headers: {
            Token: `Bearer ${id}`,
          },
        }
      );
      console.log(res);
      Toast.fire({
        icon: "success",
        title: "Client's payment confirmed",
      });
      setRefreshTable(!refreshTable);
    } catch {
    } finally {
      setLoading(false);
    }
  };
  const confirmArtisanPayment = async id => {
    setLoading(true);
    try {
      await axios.post(
        "https://helpclean-artisan-jgn56hmtbq-uc.a.run.app/v1/helpclean_artisan_api/update_payment_status",
        {
          status: "paid",
          booking_id: bookingId,
        },
        {
          headers: {
            Token: `Bearer ${id}`,
          },
        }
      );

      Toast.fire({
        icon: "success",
        title: "Artisan payment confirmed",
      });
      setRefreshTable(!refreshTable);
    } catch {
      Toast.fire({
        icon: "error",
        title: "An error occured, try again",
      });
    } finally {
      setLoading(false);
    }
  };

  const actions = (
    <Menu className="dropdown">
      <Menu.Item
        key="1"
        className="dropdown-menu"
        onClick={() => history.push("/client-profile")}
      >
        <p>View Client</p>
      </Menu.Item>
      <Menu.Item
        key="2"
        className="dropdown-menu"
        onClick={() => history.push("/user-profile")}
      >
        <p>View Artisan</p>
      </Menu.Item>
      <Menu.Item key="3" className="dropdown-menu">
        <p>Change payment status</p>
      </Menu.Item>
    </Menu>
  );

  const actions1 = (
    <Menu className="dropdown">
      <Menu.Item
        key="1"
        className="dropdown-menu"
        onClick={() => history.push("/client-profile")}
      >
        <p>View Client</p>
      </Menu.Item>
      <Menu.Item
        key="2"
        className="dropdown-menu"
        onClick={() => history.push("/user-profile")}
      >
        <p>View Artisan</p>
      </Menu.Item>
      <Menu.Item
        key="3"
        className="dropdown-menu"
        onClick={() => {
          callApiWithToken(confirmArtisanPayment);
        }}
      >
        <p>Confirm Artisan Payment</p>
      </Menu.Item>
    </Menu>
  );

  const actions2 = (
    <Menu className="dropdown">
      <Menu.Item
        key="1"
        className="dropdown-menu"
        onClick={() => history.push("/client-profile")}
      >
        <p>View Client</p>
      </Menu.Item>
      <Menu.Item
        key="2"
        className="dropdown-menu"
        onClick={() => history.push("/user-profile")}
      >
        <p>View Artisan</p>
      </Menu.Item>
      <Menu.Item
        key="3"
        className="dropdown-menu"
        onClick={() => {
          callApiWithToken(confirmClientPayment);
        }}
      >
        <p>Confirm Client Payment</p>
      </Menu.Item>
    </Menu>
  );

  const showAction = param => {
    if (
      param.payment_method === "direct_bank_transfer" &&
      param.invoice_status === "confirming_payment"
    ) {
      return actions2;
    } else if (param.invoice_status === "processing") {
      return actions1;
    } else {
      return actions;
    }
  };

  const formatWork = category => {
    if (category) {
      return (
        category.charAt(0).toUpperCase() + category.slice(1).replace(/_/g, " ")
      );
    } else {
      return category;
    }
  };
  useEffect(() => {
    callApiWithToken(fetchPayments);
  }, [refreshTable]);

  const columns = [
    {
      key: "artisan_bank_account_name",
      title: "Artisan Name",
      dataIndex: "artisan_bank_account_name",
      width: 300,
      render: text => <div>{text}</div>,
    },
    {
      key: "booking_id",
      title: "Booking Id",
      dataIndex: "booking_id",
      render: text => <div>{text}</div>,
    },
    {
      key: "invoice_status",
      title: "Invoice Status",

      dataIndex: "invoice_status",
      render: text => <div>{formatWork(text)}</div>,
    },
    {
      key: "payment_method",
      title: "Payment Method",
      dataIndex: "payment_method",
      render: text => <div>{formatWork(text)}</div>,
    },
    {
      key: "payment_date",
      title: "Payment Date",
      dataIndex: "payment_date",
      render: text => <div>{text}</div>,
    },
    {
      key: "action",
      title: "",
      width: 100,
      dataIndex: "",
      render: record => (
        <Dropdown
          overlay={showAction(record)}
          placement={"bottomRight"}
          trigger={["click"]}
        >
          <button
            className="manage"
            onClick={() => {
              setBookingId(record.booking_id);
              setArtisanId(record.artisan_id);
              setClientId(record.client_id);
            }}
          >
            Manage
          </button>
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={payments}
      loading={loading}
      pagination={true}
      scroll={{ y: "51vh", x: "max-content" }}
    />
  );
}
