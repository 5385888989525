import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./components/auth/auth-context/AuthContext";
import Signin from "./components/auth/Signin";
import DashBoard from "./components/admin-dashboard/DashBoard";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import UserProfile from "./components/admin-dashboard/main/main-component/users/user-profile/UserProfile";
import LoanDetails from "./components/admin-dashboard/main/main-component/loan/loan-details/UserLoan";
import ClientProfile from "./components/admin-dashboard/main/main-component/clients/clients-profile/ClientProfile";


import "./App.scss";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "antd/dist/antd.css";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Signin} />
            <Route path="/user-profile" component={UserProfile} />
            <Route path="/loan-details" component={LoanDetails} />
            <Route path="/client-profile" component={ClientProfile} />
            <ProtectedRoute path="/dashboard" component={DashBoard} />
          </Switch>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
