import React from "react";
import "./Home.css";
import { useAuthContext } from "../../../../auth/auth-context/AuthContext";

export default function Home() {
  const context = useAuthContext();
  const { totalUsers, totalPersonalLoans, totalBusinessLoans } = context;
  return (
    <div className="home-wrapper">
      <div className="welcome-div">
        <h1>Dashboard Overview</h1>
      </div>
      <div className="dashboard-cards">
        <div className="dashboard-card">
          <div className="card-icon">
            {" "}
            <span>
              <i className="las la-users"></i>
            </span>
          </div>
          <div className="card-description">
            <p>Total Users</p>
          </div>
          <div className="card-value">{totalUsers}</div>
        </div>
        <div className="dashboard-card">
          <div className="card-icon">
            {" "}
            <span>
              <i className="las la-money-check-alt"></i>
            </span>
          </div>
          <div className="card-description">
            <p>Personal Loans</p>
          </div>
          <div className="card-value">{totalPersonalLoans}</div>
        </div>
        <div className="dashboard-card">
          <div className="card-icon">
            {" "}
            <span>
              <i className="las la-business-time"></i>
            </span>
          </div>
          <div className="card-description">
            <p>Business Loans</p>
          </div>
          <div className="card-value">{totalBusinessLoans}</div>
        </div>
      </div>
    </div>
  );
}
