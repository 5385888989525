import React from "react";
import { NavLink } from "react-router-dom";
import "./Aside.scss";
import { useAuthContext } from "../../auth/auth-context/AuthContext";
import { Toast } from "../../styled-components/Toast";

export default function AsideMenu() {
  const context = useAuthContext();
  const { signout } = context;

  const logOut = async () => {
    await signout();
    Toast.fire({
      icon: "info",
      title: "You are Logged Out",
    });
  };
  return (
    <div className="aside-menu">
      <div className="nav-links">
        <NavLink
          exact
          to="/dashboard"
          activeClassName="aside-active"
          className="nav-link"
        >
          <span>
            <i className="las la-home"></i>
          </span>
          Home
        </NavLink>
      </div>

      <div className="nav-links">
        <NavLink
          to="/dashboard/artisans"
          activeClassName="aside-active"
          className="nav-link"
        >
          <span>
            <i className="las la-users"></i>
          </span>
          View All Users
        </NavLink>
      </div>

      <div className="nav-links">
        <NavLink
          to="/dashboard/jobs"
          activeClassName="aside-active"
          className="nav-link"
        >
          <span>
            <i className="las la-hand-holding-usd"></i>
          </span>
          View All Loans
        </NavLink>
      </div>
      <div className="aside-footer">
        <div className="separator"></div>
        <div className="nav-links"></div>
        <div className="nav-links" onClick={logOut}>
          <li className="nav-link">
            <span>
              <i className="las la-power-off"></i>
            </span>
            Log Out
          </li>
        </div>
      </div>
    </div>
  );
}
